







import Vue from 'vue'
import edit from '@/components/admin/class-designer/edit.vue'

export default Vue.extend({
  components: {
    edit,
  },
  data() {
    return {
      id: '',
    }
  },
  mounted() {
    this.id = this.$route.params.id
  },
})
